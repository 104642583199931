const curFormation = [
    {nom: "BTS", options: ['Bâtiment', 'TP']},
    {nom: "DUT/BUT", options: ["Génie Civil Construction Durable"]},
    {nom: "Conducteurs de Travaux ESTP", options: ["CB", "CTP", "CAB", "CATP"]},
    {nom: "Licence", options: ["L3 - Génie Civil"]},
    {nom: "ESITC Paris", options: ["TC2"]},
    {nom: "Autre", options:[]},

]

export default curFormation;